import React, { useState, useEffect } from "react";
import "./Post.css";
import axios from "axios";

const postData = require("../data/post.json");
const authorsData = require("../data/authors.json");

function Post() {
  const [posts, setPosts] = useState(null);
  const [authors, setAuthors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);



  // const [posts, setPosts] = useState([...postData]);
  // const [authors, setAuthors] = useState([...authorsData]);

  console.log("posts",posts);
  console.log("authors",authors);

  async function fetchAuthors() {


    try {
      const response = await axios.get('https://cors-anywhere.herokuapp.com/https://fb.api.dev.julaherb.co/authors.json');
      setAuthors(response.data);
      const res = await axios.get('https://cors-anywhere.herokuapp.com/https://fb.api.dev.julaherb.co/posts.json');
      setPosts(res.data);
      // Handle the response data
      setIsLoading(true);
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  }



  useEffect(() => {
    fetchAuthors()



  }, [])





  function getAuthorData(id) {
    const data = authors.find((item) => {
      return id === item.id;
    });
    return data

    // const data = authors.filter((item) => {
    //   return id === item.id;
    // });
    // return data[0]
  }

  return (
    <div className="post-container">
      <h2>Your current timezone is: Asia/Bangkok
        <a href="https://cors-anywhere.herokuapp.com" target="_blank">cors-anywhere</a>
      </h2>
      <div className="post-group">
        {
          isLoading ?
            posts.map((post) => {
              const author = getAuthorData(post.author_id);

              return (
                <div className="post-item" key={post.id}>
                  <div className="user">
                    <img src={author.avatar_url} alt="avatar" />
                    <h4 className="user-name">
                      {author.name}{" "}
                      <span className="time">
                        posted on {convertDate(post.created_at)}{" "}
                      </span>
                    </h4>
                  </div>

                  <hr />
                  <div className="content">
                    <img src={post.image_url} />
                    <div className="right-content">
                      <h3 className="toppic">{post.title}</h3>
                      <p>{post.body}</p>
                    </div>
                  </div>
                </div>
              );
            })

            :
            <h1>isLoading</h1>

        }
      </div>
    </div>
  );
}

function convertDate(dateString) {
  // let dateString = "2020-05-08T17:01:15Z";
  let date = new Date(dateString);
  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let day = days[date.getUTCDay()];
  let shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let shortMonth = shortMonths[date.getMonth()];
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  // console.log("hours", hours);
  let formattedHours = hours.toString().padStart(2, "0");
  let formattedMinutes = minutes.toString().padStart(2, "0");
  let formattedDate =
    day +
    ", " +
    shortMonth +
    " " +
    date.getDate() +
    ", " +
    year +
    ", " +
    formattedHours +
    ":" +
    formattedMinutes;
  // console.log(formattedDate);
  return formattedDate;
}

export default Post;
